import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as attendanceForm from '../../../api/appApi/attendanceForm';
import { notificationActions } from '../notification';
import { navigate } from '../../../lib/utils/navigation';
import * as actions from './actions';
import { apiActions, apiSelectors } from '../api';
import * as types from './types';
import * as selectors from './selectors';
import { attendanceFormActions } from '.';


export function* getForms(payload) {
	

	yield put(apiActions.setQueryFilter(payload.data));

	const status = payload.data && payload.data.status ? payload.data.status : null
	const tecnico = payload.data && payload.data.tecnico ? payload.data.tecnico : null
	const id = payload.data && payload.data.id ? payload.data.id : null


	try {
		let response = yield call(attendanceForm.getForms, { id, tecnico, status})



		if (id) {
			const anexos = yield call(attendanceForm.getAnexo, { id })
			const servs = yield call(attendanceForm.getServPrev, { id })
			const rows = yield call(attendanceForm.getRowsItens, { id })
			yield put(attendanceFormActions.servicosPrev(servs.data.data))
			response.data.data.anexos = anexos.data.data.response
			yield put(attendanceFormActions.setRowsItens(rows.data.data))

		}




		yield put(attendanceFormActions.setList(response.data.data))

	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar por formulários.',
				'error',
			),
		);
	}
}

export function* getSit(payload) {
	try {
		const response = yield call(attendanceForm.getSits)
		console.log(response)
		yield put(attendanceFormActions.setSit(response.data.data))
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao buscar por situações.',
				'error',
			),
		);
	}
}


export function* newForm(payload) {
	const { data } = payload
	const servicos = yield select(selectors.getServs)
	const user = yield select(selectors.getUser)
	console.log(user)
	data.user_id = user.id
	data.user_name = user.username
	data.serv = servicos
	try {
		
		const response = yield call(attendanceForm.post, data);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar cadastro de Formulário de atendimento.',
					'success',
				),
			);


			setTimeout(() => {
				navigate('/form-atendimento');
			}, 1200);

		} else {
			yield put(
				notificationActions.addNotification(
					'Erro ao realizar cadastro de  Formulário de atendimento.',
					'error',
				),
			);
		}

	} catch (e) {
		console.log(e)
		yield put(
			notificationActions.addNotification(
				'Erro ao realizar cadastro de  Formulário de atendimento.',
				'error',
			),
		);
	}
}

export function* upload(payload) {
	const file = payload.query;

	try {
		const data = new FormData();
		data.append('import', file);
		const response = yield call(attendanceForm.upload, file);

	} catch (error) {
		console.log(error)
	}
}

export function* getClie() {
	try {
		const response = yield call(attendanceForm.getClie)

		yield put(actions.setClie(response.data.data))
	} catch (error) {
		console.log(error)
	}
}

export function* getNro(payload) {

	try {
		const response = yield call(attendanceForm.getNro, { id: payload.cliId })
		yield put(actions.setNro(response.data.data))

	} catch (error) {
		console.log(error)
	}
}

export function* getAtend() {

	try {
		const response = yield call(attendanceForm.getAtend)
		yield put(actions.setAtend(response.data.data))
	} catch (error) {
		console.log(error)
	}
}

export function* getListManPrev() {
	try {
		const responseSetores = yield call(attendanceForm.getSetores)
		yield put(actions.setListManPrev(responseSetores.data.data))
	} catch (error) {
		console.log(error)
	}
}

export function* getItens() {
	try {
		const response = yield call(attendanceForm.getItens)

		yield put(actions.setItens(response.data.data))
	} catch (error) {
		console.log(error)
	}
}
export function* setItems(payload) {
	console.log(payload)
	const { item } = payload;
	const items = yield select(selectors.getItems);
	const add = items.some(val => item.id === val.id);

	if (!add) {
		yield put(actions.addItem(item));
	} else {
		const { field } = payload;
		const index = items.findIndex(v => v.id === item.id);
		yield put(actions.editItem(item, index, field));
	}
}

export function* deleteForm(payload) {
	try {
		const response = yield call(attendanceForm.deleteForm, payload.data)
		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Formulário deletada com sucesso!',
					'success',
				),
			);
			yield put(apiActions.toogleModal());
			yield put(actions.getList());

		}

	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao deletar Formulário.',
				'error',
			),
		);
	}
}

export function* onNavigateEdit(payload) {
	sessionStorage.setItem('id', payload.data)
	
	setTimeout(() => {
		navigate('/form-atendimento/register');
	}, 1200);
}

export function* onEdit(payload) {
	const servicos = yield select(selectors.getServs)
	payload.data.data.servs = servicos


	try {
		const response = yield call(attendanceForm.put, payload);

		if (response.status) {
			yield put(
				notificationActions.addNotification(
					'Sucesso ao realizar Edição de Formulário de atendimento.',
					'success',
				),
			);
			setTimeout(() => {
				navigate('/form-atendimento');
			}, 1200);
		}
	} catch (error) {
		yield put(
			notificationActions.addNotification(
				'Erro ao realizar Edição de  Formulário de atendimento.',
				'error',
			),
		);
	}
}

export function* generatePdf(payload) {
	try {
		console.log(payload)
		const response = yield call(attendanceForm.generatePdf(payload.id))
	} catch (error) {
		console.log(error)
	}
}

export function* submitRp(payload) {
	
	const user = yield select(selectors.getUser)
	let data = {}
	console.log(user)
	data.atend_id = payload.data.id
	data.clie = payload.data.cli_id
	data.obs = payload.data.obs
	data.id_usu = user.extra_id
	data.username = user.username
	try {
		const response = yield call(attendanceForm.submitRP(data))
	} catch (error) {
		console.log(error)
		
	}
}

export default function* watchAttendanceForm() {

	yield takeLatest(types.NEW_FORM, newForm);
	yield takeLatest(types.UPLOAD, upload);
	yield takeLatest(types.GET_CLIE, getClie);
	yield takeLatest(types.GET_NRO, getNro);
	yield takeLatest(types.GET_ATEND, getAtend)
	yield takeLatest(types.GET_LIST_PREV, getListManPrev)
	yield takeLatest(types.GET_ITENS, getItens)
	yield takeLatest(types.GET_LIST, getForms)
	yield takeLatest(types.SET_ITEMS_BUDGET, setItems);
	yield takeLatest(types.DELETE_FORM, deleteForm);
	yield takeLatest(types.ON_NAVIGATE_EDIT, onNavigateEdit);
	yield takeLatest(types.ON_EDIT, onEdit);
	yield takeLatest(types.GET_SIT, getSit);
	yield takeLatest(types.GENERATE_PDF, generatePdf);
	yield takeLatest(types.SUBMIT_RP, submitRp);




}

import * as types from './types';


export const newForm = data => ({
	type: types.NEW_FORM,
	data
})

export const upload =  query => ({
	type: types.UPLOAD,
	query
})

export const getClie = () => ({
	type: types.GET_CLIE
})

export const setClie = data => ({
	type: types.SET_CLIE,
	data
})

export const getNro = cliId => ({
	type: types.GET_NRO,
	cliId
})

export const setNro = data => ({
	type: types.SET_NRO,
	data
})

export const getAtend = () => ({
	type: types.GET_ATEND,
})

export const setAtend = data => ({
	type: types.SET_ATEND,
	data
})

export const getListManPrev = () => ({
	type: types.GET_LIST_PREV,
})

export const setListManPrev = data => ({
	type: types.SET_lIST_PREV,
	data
})

export const getItens = () => ({
	type: types.GET_ITENS,
})

export const setItens = data => ({
	type: types.SET_ITENS,
	data
})

export const getList = data => ({
	type: types.GET_LIST,
	data
})

export const setList = data => ({
	type: types.SET_LIST,
	data
})

export const addItem = item => ({
	type: types.ADD_ITEM_FORM,
	item,
});

export const editItem = (item, index, field) => ({
	type: types.EDIT_ITEM_FORM,
	item,
	index,
	field,
});

export const deleteForm = data => ({
	type: types.DELETE_FORM,
	data
})

export const onNavigateEdit = data => ({
	type: types.ON_NAVIGATE_EDIT,
	data
})

export const onEdit = data => ({
	type: types.ON_EDIT,
	data
})

export const setRowsItens = data => ({
	type: types.SET_ROWS_ITENS,
	data
})

export const setServsPrev = data => ({
	type: types.SET_SERV_PREV,
	data
})

export const getSit = data => ({
	type: types.GET_SIT,
	data
})

export const setSit = data => ({
	type: types.SET_SIT,
	data
})

export const servicosPrev = data => ({
	type: types.SERVICOS_PREV,
	data
})

export const generatePdf = data => ({
	type: types.GENERATE_PDF,
	data
})

export const onSubmitRP = data => ({
	type: types.SUBMIT_RP,
	data
})

export default {
	newForm,
	upload,
	getClie,
	getNro,
	setClie,
	setNro,
	getAtend,
	setAtend,
	getListManPrev,
	setListManPrev,
	getItens,
	setItens,
	getList,
	setList,
	addItem,
	editItem,
	deleteForm,
	onNavigateEdit,
	onEdit,
	setRowsItens,
	setServsPrev,
	servicosPrev,
	getSit,
	setSit,
	generatePdf,
	onSubmitRP,

	
};

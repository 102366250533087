import {
	NEW_FORM,
	SET_CLIE,
	SET_NRO,
	SET_ATEND,
	SET_lIST_PREV,
	SET_ITENS,
	SET_LIST,
	SET_ROWS_ITENS,
	SET_SERV_PREV,
	SERVICOS_PREV,
	SET_SIT,

} from './types';

const initialState = {
	list: false,
	form: [
		{
			"nros": "",
			"obs": "",
			"desconto": "",
			"conditionCharge": "",
			"doc_rg": "",
			"doc_cpf": "",
			"reclamante": "",
			"files": [],
			"authorizedBudget": "",
			"billing_conditions": "",
			"items": [
				{
					"item": "",
					"qtd": "",
					"val": "",
					"tot": ""
				}
			],
			"working_elevator": "",
			"horaChamado": "",
			"hour_initial": "",
			"hour_end": "",
			"subtotal": "",
			"arrested_client": "",
			"total": "",
			"sigPad": "",
			"cliente": "",
			"atendimento": "",
			"dt_atendimento": "",
			"dt_chamado": "",
			"reclam": ""
		}
	],
	budget: false,
	modal: false,
	rows: false,
	servicosPrev: false,
	sits: false,
};

const attendanceFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_LIST:
			return {
				...state,
				list: action.data
			}
		case NEW_FORM:
			return { ...state, form: action.data };
		case SET_CLIE:
			return {
				...state,
				clientes: action.data
			}
		case SET_NRO:
			return {
				...state,
				nro: action.data
			}
		case SET_ATEND:
			return {
				...state,
				atendimentos: action.data
			}
		case SET_lIST_PREV:
			return {
				...state,
				listPrev: action.data
			}
		case SET_ITENS:
			return {
				...state,
				itens: action.data
			}
		case SET_ROWS_ITENS:
			return {
				...state,
				rows: action.data
			}
		case SET_SERV_PREV:
			return {
				...state,
				servPrev: action.data
			}
		case SERVICOS_PREV:
			return {
				...state,
				servicosPrev: action.data
			}
		case SET_SIT:
			return {
				...state,
				sits: action.data
			}
		default:
			return state;
	}
};


export default attendanceFormReducer;
